import { useEffect, useState, forwardRef } from 'react';
import { Select, TextField, Autocomplete, Button, InputLabel, MenuItem, FormControl } from '@mui/material';
import axios from 'axios';


const data = [
    // customer start
    'applicant_pan',

    // 'guarantor_pan',
    //'Partner pan',
    // 'director_pan',
    // 'Applicant Aadhaar Front',
    'bank_statement',
    'aadhaar_front',
    'aadhaar_back',
    'residence_electricity_bill',
    'business_electricity_bill',
    'other_ownership_proof',
    'applicant_photo',
    'applicant_voter_id',
    'coapplicant_voter_id',
    'Coapplicant_dl',
    'coapplicant_passport',
    'coapplicant_photo',
    'coapplicant_aadhaar_front',
    'coapplicant Aadhaar Back',
    'coapplicant_pan',

    // 'guarantor_voter_id',
    //'Partner Voter ID',
    // 'director_voter_id',
    'applicant_dl',

    // 'guarantor_dl',
    //'Partner DL',
    // 'director_dl',
    'applicant_passport',

    // 'guarantor_passport',
    //'Partner Passport',
    // 'director_passport',
    'ownership_proof',

    // 'guarantor_photo',
    // 'Partner Photo',
    // 'director_photo',
    // ----customer end

    // 'Applicant Aadhaar Back',
    // 
    // 'guarantor_aadhaar_front',
    // 'guarantor_aadhaar_back',
    //'Partner Aadhaar Front',
    //'Partner Aadhaar Back',
    // 'director_aadhaar_front',
    // 'director_aadhaar_back',

    // business details
    'gst_certificate',
    'fssai_certificate',
    'shop_and_establishment_certificate',
    'msme_certificate',
    // 'bye_laws',
    // 'moa',
    // 'aoa',
    // business details end






    // loan details start
    'erick_delivery_photo',
    'loan_agreement',
    'trc',
    'insurance',
    'form_20',
    'form_21',
    'form_22',
    'cheques',
    // loan detais end


    // bank details
    'invoice_or_quotation',
    'crif_report',
    'erick_delivery_photo',
    'stamp_paper',
    'delivery_order',
    'loan_agreement',
    'sanction_letter',
    'hypothecation_agreement'
    // bank details end


]

const co_customer = ['coapplicant_voter_id',
'Coapplicant_dl',
'coapplicant_passport',
'coapplicant_photo',
'coapplicant_aadhaar_front',
'coapplicant Aadhaar Back',
'coapplicant_pan']

// const anchor = [];
const customer = ['applicant_pan', 'aadhaar_front', 'aadhaar_back', 'residence_electricity_bill',
    'business_electricity_bill',
    'other_ownership_proof',
    'applicant_photo',
    'applicant_voter_id',
    'applicant_dl',
    'applicant_passport',
    'ownership_proof',
];

const business = [
    'gst_certificate',
    'fssai_certificate',
    'shop_and_establishment_certificate',
    'msme_certificate',
];

const bank = [
    'cheques',
    'bank_statement'
];

const loan = [
    'erick_delivery_photo',
    'loan_agreement',
    'trc',
    'insurance',
    'form_20',
    'form_21',
    'form_22',
    'crif_report',
    'invoice_or_quotation',
    'erick_delivery_photo',
    'stamp_paper',
    'delivery_order',
    'loan_agreement',
    'sanction_letter',
    'hypothecation_agreement'
];

const baseurl = "https://api.lms.embifi.in/api";

export default function DocsUpload() {

    const [custType, setCustType] = useState('customer');

    const [custId, setCustId] = useState("");
    const [custIdList, setCustIdList] = useState([]);

    const [anchorId, setAnchorId] = useState("");
    const [anchorIdList, setAnchorIdList] = useState([]);

    const [docType, setDocType] = useState([]);
    const [selectedDoc, setSelected] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [fileList, setFileList] = useState([{ 'name': '', 'file': '', 'collection': '' }]);

    const [apiRes, setApiRes] = useState({ message: "", link: "" });


    const getBase64 = (file) => {
        return new Promise((resolve) => {
            let fileInfo;
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    const getCustomerId = () => {
        let customeridList = [];

        var config = {
            method: 'get',
            url: baseurl + '/v1/lms/getLoanAndCustomer',
            headers: {}
        };

        axios(config)
            .then(function (response) {
                response?.data?.data?.map((val, ind) => {
                    if (val?.customerData?.is_corporate == false) {
                        customeridList = [...customeridList, { 'application_id': val?.application_id, 'label': `${val?.application_id} : ${val?.customerData?.pan_details?.name}` }]
                    }
                    else {
                        customeridList = [...customeridList, { 'application_id': val?.application_id, 'label': `${val?.application_id} : ${val?.customerData?.corporate_pan_details?.name}` }]
                    }
                })
                setCustIdList(customeridList)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const getAnchorId = () => {
        let anchoridList = [];

        var config = {
            method: 'get',
            url: baseurl + '/v1/lms/anchors',
            headers: {}
        };

        axios(config)
            .then(function (response) {
                response?.data?.anchor?.map((val, ind) => {
                    if (!(anchoridList.includes(val?.anchor_id))) {
                        anchoridList = [...anchoridList, val?.anchor_id]
                    }

                })
                setAnchorIdList(response?.data?.anchor);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const uploadDocs = () => {
        setIsLoading(true);
        let newValues = [...fileList]
        newValues.map((e, i) => {
            if (e.name === "" || e.file === "" || e.collection === "") {
                newValues.splice(i, 1)
            }
        })
        setFileList(newValues);
        var config = {
            method: 'put',
            url: baseurl + '/v1/lms/upload',
            headers: {},
            // data: { "anchor_id": anchorId, "application_id": custId, "docs": fileList }
            data: { "anchor_id": anchorId, "application_id": custId, "docs": newValues }
        };

        axios(config)
            .then(function (response) {
                setIsLoading(false);
                console.log(JSON.stringify(response.data));
                setApiRes({ message: response.data.message, link: response.data.url })
            })
            .catch(function (error) {
                setIsLoading(false);
                setApiRes({ message: error.message })
                console.log(error);
            });

    }

    const handleSubmit = () => {
        uploadDocs();
    }

    // const handleSelect = (e) => {
    //     let k = e.target.files[0];
    //     let base64result = "";
    //     let collName = "";

    //     if (customer.includes(selectedDoc)) {
    //         collName = 'customer'
    //     }
    //     else if (business.includes(selectedDoc)) {
    //         collName = 'business'
    //     }
    //     else if (bank.includes(selectedDoc)) {
    //         collName = 'bank'
    //     }
    //     else if (loan.includes(selectedDoc)) {
    //         collName = 'loan'
    //     }

    //     getBase64(k).then(result => {
    //         setDocType([...docType, { 'name': selectedDoc, 'file': result, 'collection': collName }])
    //         setSelected("");
    //     });
    //     setSelected("");
    // }

    const addFormFields = () => {
        setFileList([...fileList, { name: "", file: "", collection: "" }])
    }

    const handleChange = (i, e) => {
        let base64Temp = "";
        let fileTemp = e.target.files[0]
        let newFormValues = [...fileList];

        getBase64(fileTemp).then(result => {
            newFormValues[i].file = result
            setFileList(newFormValues);
        });
    }

    const handleChangeDocType = (i, e) => {
        let collName = "";
        let targetValue = e.target.value
        let tempState = [...fileList];
        tempState[i].name = targetValue;


        if (customer.includes(targetValue)) {
            collName = 'customer'
            tempState[i].collection = 'customer';
        }
        else if (business.includes(targetValue)) {
            collName = 'business'
            tempState[i].collection = 'business';
        }
        else if (bank.includes(targetValue)) {
            collName = 'bank'
            tempState[i].collection = 'bank';
        }
        else if (loan.includes(targetValue)) {
            collName = 'loan'
            tempState[i].collection = 'loan';
        }
        else if (co_customer.includes(targetValue)) {
            collName = 'co-customer'
            tempState[i].collection = 'co-customers';
        }

        // tempState[i].collection = collName;

        setFileList(tempState);

    }

    useEffect(() => {
        console.log(docType);
    }, [docType])

    useEffect(() => {
        getCustomerId();
        getAnchorId();
    }, [])

    useEffect(() => {
        console.log(fileList)
    }, [fileList])



    return (
        <>
            {
                isLoading ?
                    <h1 style={{ color: '#fff' }}>Loading...</h1>
                    :
                    <div style={{ background: '#fff', minHeight: '100vh', minWidth: '100vw', padding: '2em', overflow: 'scroll' }}>

                        {
                            apiRes?.message ?
                                <div style={{ textAlign: 'center', marginBottom: '1em', padding: '0.5em', background: 'grey', borderRadius: '10px' }}>
                                    <h5>{apiRes?.message}</h5>
                                    <h5><a href={apiRes?.link} target="blank">{apiRes?.link}</a></h5>
                                </div>
                                : null}

                        <div className='d-flex align-items-center'>
                            <FormControl sx={{ width: '12em' }}>
                                <InputLabel>Customer Type</InputLabel>
                                <Select
                                    value={custType}
                                    label="Customer Type"
                                    onChange={(e) => setCustType(e.target.value)}
                                >
                                    <MenuItem value={'customer'}>Customer</MenuItem>
                                    {/* <MenuItem value={'anchor'}>Anchor</MenuItem> */}
                                </Select>
                            </FormControl>

                            {custType == 'customer' ?
                                <Autocomplete
                                    disablePortal
                                    options={custIdList}
                                    sx={{ width: '30em', ml: '1em' }}
                                    onChange={(e, newVal) => setCustId(newVal?.application_id)}
                                    renderInput={(params) => <TextField {...params} label="Application Id" />}
                                />
                                : null}

                            {custType == 'anchor' ?
                                <FormControl sx={{ width: '12em', ml: '1em' }}>
                                    <InputLabel>Anchor Id</InputLabel>
                                    <Select
                                        value={anchorId}
                                        label="Anchor Id"
                                        onChange={(e) => setAnchorId(e.target.value)}
                                    >
                                        {
                                            Object.keys(anchorIdList)?.map((val, ind) => {
                                                return <MenuItem value={anchorIdList[val]?.anchor_id} key={ind}>{`${anchorIdList[val]?.anchor_id} : ${anchorIdList['pan_details']?.name}`}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                : null}

                            <Button variant="contained" sx={{ ml: '1em' }} onClick={addFormFields}>+ADD</Button>

                        </div>

                        <div style={{ maxHeight: '40em', overflow: 'scroll' }}>
                            {
                                fileList.map((element, index) => {
                                    return (
                                        <div style={{ marginTop: '1em', display: 'flex', alignItems: 'center' }}>
                                            {/* <Autocomplete
                                                disablePortal
                                                options={data}
                                                key={index}
                                                sx={{ width: '16em', ml: '1em' }}
                                                onChange={(e, newVal) => setSelected(newVal)}
                                                renderInput={(params) => <TextField {...params} label="Document Type" />}
                                            /> */}
                                            <FormControl sx={{ width: '16em', ml: '1em' }}>
                                                <InputLabel>Document Type</InputLabel>
                                                <Select
                                                    label="Customer Type"
                                                    onChange={(e) => handleChangeDocType(index, e)}
                                                >
                                                    {
                                                        data.map((val, ind) => {
                                                            return <MenuItem value={val} key={ind}>{val}</MenuItem>
                                                        })
                                                    }

                                                </Select>
                                            </FormControl>
                                            <input style={{ marginLeft: '2em' }} key={index} type='file' onChange={(e) => handleChange(index, e)} />
                                        </div>
                                    )
                                })
                            }


                            <Button style={{ marginTop: '2em', marginBottom: '2em' }} variant="contained" onClick={handleSubmit}>Submit</Button>

                            <Button style={{ marginTop: '2em', marginLeft: '1em', marginBottom: '2em' }} variant="contained" onClick={() => window.location.reload()}>Reset</Button>

                        </div>


                    </div>
            }
        </>
    );
}