export const ParsersList = {
  "Bank Statement": "/parser/bank-statement",
  Payslip: "/parser/payslip",
  Invoice: "/parser/invoice",
  Cheque: "/parser/cheque",
  Pan: "/parser/pan",
  Aadhaar: "/parser/aadhaar",
};

export const BanksList = [
  "Abhyudaya Co-operative Bank Ltd, ABHY",
  "Access Bank, ACC",
  "Adarsh Co-operative Bank Ltd., ACBL",
  "Airtel Payments Bank, APB",
  "Allahabad Bank, ALLA",
  "Ambarnath Jai Hind Co Op Bank Ltd., AJHC",
  "Andhra Bank, ANDH",
  "Andhra Pradesh Grameena Vikas Bank, APGVB",
  "Andhra Pragathi Grameena Bank, APGB",
  "Apna Sahakari Bank Ltd., ASBL",
  "Au Small Finance Bank Ltd., ASFB",
  "Axis Bank Ltd., AXIS",
  "Bank for Agriculture and Agricultural Cooperatives, BAAC",
  "Banco Comercial Português, BCP",
  "Bandhan Bank Ltd., BBL",
  "Bangkok Bank, BANB",
  "Bank of America, BOA",
  "Bank of Ayudhya, BAY",
  "Bank of Baroda, BOB",
  "Bank of India, BOI",
  "Bank of Maharashtra, BOM",
  "The Baramati Sahakari Bank Ltd., BARA",
  "Bassein Catholic Co-operative Bank Ltd., BCCB",
  "Bhagini Nivedita Sahakari Bank Ltd., BNSB",
  "The Bhagyalakshmi Mahila Sahakari Bank Ltd., BLMS",
  "Bharat Co-operative Bank (Mumbai) Ltd., BCBM",
  "Bhausaheb Sahakari Bank Ltd., BSBL",
  "The Bhavnagar District Co-operative Bank Ltd, BDCB",
  "Canara Bank, CAN",
  "Catholic Syrian Bank Ltd., CSB",
  "Central Bank of India, CBI",
  "Chattisgarh Rajya Gramin Bank, CRGB",
  "Citibank N.A., CITI",
  "City Union Bank Ltd., CUBL",
  "Corporation Bank, CORP",
  "Cosmos Co-operative Bank Ltd, COSB",
  "DBS Bank Ltd., DBS",
  "DCB Bank Ltd., DCB",
  "Deendayal Nagari Sahakari Bank Ltd., DNSB",
  "Dena Bank, DENA",
  "Deogiri Nagari Sahakari Bank, DEOB",
  "Dhanlaxmi Bank Ltd., DHAN",
  "DMK Jaoli Bank Ltd., DMKJ",
  "Dombivli Nagari Sahakari Bank Ltd., DNS",
  "Equitas Small Finance Bank Ltd, ESFB",
  "ESAF Small Finance Bank Ltd., ESAF",
  "Federal Bank Ltd., FBL",
  "Fincare Small Finance Bank Ltd., FSFB",
  "Fino Payments Bank, FPB",
  "Gayatri Co-operative Urban Bank Ltd., GCUB",
  "Government Savings Bank, GSB",
  "GP Parsik Sahakari Bank Ltd., GPPB",
  "Greater Bombay Co-operative Bank Ltd, GBCB",
  "Gulshan Mercantile Urban Coop. Bank Ltd, GMUC",
  "The Hasti Co - Op Bank Ltd., HCBL",
  "HDFC Bank Ltd, HDFC",
  "HSBC Ltd, HSBC",
  "ICICI Bank Ltd., ICICI",
  "IDFC Bank Ltd., IDFC",
  "Inkredo, INKR",
  "India Post Payments Bank, IPPB",
  "Indian Bank, INDB",
  "Indian Overseas Bank, IOB",
  "IndusInd Bank Ltd, IIBL",
  "J.P. Morgan Chase Bank N.A., JPMC",
  "Jammu & Kashmir Bank Ltd., JKBL",
  "Jammu & Kashmir Grameen Bank, JKGB",
  "Jana Small Finance Bank Ltd, JSFB",
  "Janaseva Sahakari Bank Ltd., JSBL",
  "Janata Sahakari Bank Ltd., JSBP",
  "Jharkhand Gramin Bank, JGB",
  "Jharkhand Rajya Gramin Bank, JRGB",
  "Kallappanna Awade Ichalkaranji Janata Sahakari Bank, KAIJ",
  "Kalyan Janata Sahakari Bank, KJSB",
  "Karnataka Bank Ltd., KBL",
  "The Karnataka State Co-Operative Apex Bank Ltd., KSCB",
  "Karnataka Vikas Grameena Bank, KVGB",
  "Karur Vysya Bank Ltd., KVBL",
  "Kasikornbank, KKB",
  "Krishna Bhima Samruddhi LAB Ltd, KBSL",
  "Krung Thai Bank Public Co. Ltd., KTBP",
  "Lakshmi Vilas Bank Ltd., LVBL",
  "M&T Bank, MTB",
  "Maharashtra Gramin Bank, MAGB",
  "Makarpura Ind Estate Co Op Bank Ltd, MIEB",
  "Nainital Bank Ltd., NBL",
  "The Nandura Urban Co-operative Bank Ltd., NUCB",
  "New India Co-Operative Bank Ltd, NICB",
  "Niyo Solutions Inc., NIYO",
  "NKGSB Co-operative Bank Ltd., NKGS",
  "North East Small Finance Bank Ltd, NESF",
  "NSDL Payments Bank Limited, NSDL",
  "Oriental Bank of Commerce, OBC",
  "Oversea-Chinese Banking Corporation, Limited, OCBC",
  "PayTM Payments Bank, PAYTM",
  "Paschim Banga Gramin Bank, PBGB",
  "Priyadarshani Nagari Sahakari Bank Ltd, PNSB",
  "Pune Cantonment Sahakari Bank Ltd, PCSB",
  "Pune People's Co-operative Bank, PPCB",
  "Punjab & Sind Bank, PSB",
  "Punjab National Bank, PNB",
  "Rajasthan Marudhara Gramin Bank, RMGB",
  "RBL Bank Ltd., RBL",
  "Saraswat Co-operative Bank, SCOB",
  "The Seva Vikas Co-operative Bank Limited, SVBL",
  "Shamrao Vithal Co-operative Bank, SVCB",
  "Shree Kadi Nagarik Sahakari Bank Ltd, KNSB",
  "Shree Mahesh Co- Op Bank Limited, SMCB",
  "Shri Chhatrapati Rajarshi Shahu Urban Co-Operative Bank, CRUB",
  "Siam Commercial Bank, SIAM",
  "South Indian Bank Ltd., SIB",
  "Standard Chartered Bank, SCB",
  "State Bank of India, SBI",
  "The Surat People's Co-Op. Bank Ltd., SPCB",
  "Suryoday Small Finance Bank Ltd., SSFB",
  "Syndicate Bank, SYNB",
  "Tamilnad Mercantile Bank Ltd., TMBL",
  "Tamluk Ghatal Central Co-operative Bank Ltd., TGCB",
  "Telengana Grameena Bank, TEGB",
  "The Access Bank UK, ACCUK",
  "The Municipal Co-Op. Bank Ltd., MCBL",
  "TJSB Sahakari Bank Ltd, TJSB",
  "TMBThanachart Bank Public Company Limited, TTB",
  "UCO Bank, UCOB",
  "Ujjivan Small Finance Bank Ltd., USFB",
  "Union Bank of India, UBI",
  "United Bank of India, UNBI",
  "Utkarsh Small Finance Bank Ltd., UTSF",
  "Uttarakhand Gramin Bank, UTGB",
  "Vananchal Gramin Bank, VGB",
  "Vasai Vikas Sahakari Bank Ltd., VVSB",
  "Vijaya Bank, VIJ",
  "The Vishweshwar Sahakari Bank Ltd., VSBL",
  "Woori Bank, WOOB",
  "YES Bank Ltd., YESB",
  "IDBI Bank Limited, IDBI",
  "Kotak Mahindra Bank Ltd, KOTAK",
];
