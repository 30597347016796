import axios from "axios";

const API = axios.create({
  baseURL: "https://api.common-wrapper.embifi.in/v1",
  withCredentials: true,
  // "Access-Control-Allow-Credentials": true,
});

export const inkredoBSP = (data) =>
  axios.post(
    `https://f98fq0sv8h.execute-api.ap-south-1.amazonaws.com/dev/bankstatement/inkredo-bsp`,
    data
  );
export const parseInvoice = (data) =>
  axios.post(
    `https://f98fq0sv8h.execute-api.ap-south-1.amazonaws.com/dev/invoice/all`,
    data
  );
export const parseCheque = (data) =>
  axios.post(
    `https://f98fq0sv8h.execute-api.ap-south-1.amazonaws.com/dev/cheque`,
    data
  );
export const parsePan = (data) => API.post(`/kyc/pan-extract`, data);
export const parseAadhaar = (data) => API.post(`/kyc/aadhaar-parser`, data);

// ============== apis of no use =================

export const sendOTP = (data, method) =>
  API.post(`/user/send-otp?method=${method}`, data);
export const verifyOTP = (data) => API.post(`/user/verify-otp`, data);
export const createUser = (data) => API.post(`/user/create-user`, data);
export const fetchKyc = (data) => API.post(`/user/fetch-kyc`, data);
export const signIn = (data) => API.post(`/user/login`, data);
export const verifyAuth = () => API.get(`/user/verify-user`);
export const addName = (data) => API.put(`user/update-basic-details`, data);
export const resetPassword = (data) => API.put(`/user/update-password`, data);
export const uploadKyc = (data) => API.put(`/user/kyc-details`, data);
export const updateGstData = (data) => API.put(`/user/gst-step`, data);
export const updateBankDetails = (data) => API.put(`/user/bank-details`, data);
export const createMandate = (data) => API.post(`/user/create-mandate`, data);
export const logout = () => API.post(`/user/logout`);
export const userExist = (data) => API.post(`/user/user-exist`, data);
export const validatePincode = (data) =>
  API.get(`/user/check-pincode?pincode=${data}`);

// AADHAR ENDPOINTS

export const requestOTPAadhar = (data) =>
  API.post(`/user/aadhar-verification`, data);
export const submitOTPAadhar = (data) =>
  API.post(`/user/aadhar-submitOTP`, data);
export const confirmBankDetail = (data) =>
  API.put(`/user/bank-details/confirmed`, data);
